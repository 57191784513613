.loginContent {
    margin: 0 auto;
    padding: 30px 12px;
}

.formFields {
    width: 300px;
    margin: 2rem auto;
    display: grid;
    row-gap: 1rem;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1rem;
}

.loginButton {
    background-color: #007aff;
    color: #fff;
}

.googleSignupButton {
    background-color: #fff;
    color: #636366;
    border-color: #e1e3e7;
    margin-top: 1rem;
}

.error {
    margin: 0.6rem auto 1.4rem auto;
    display: inline-block;
    text-align: center;
    color: #ff4b4b;
}

.instructionContainer {
    padding: 12px 8px;
}

.instruction {
    color: #aeaeb2;
    text-align: center;
    font-size: 12px;
}

.link {
    color: #007aff;
    cursor: pointer;
    font-weight: 700;
}

.reset {
    cursor: pointer;
}
