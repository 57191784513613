.page {
	min-height: 100vh;
	margin: 0;
	padding: 0;
}

.titleGroup {
	max-width: 300px;
	margin: 0 auto;
	margin-bottom: 2rem;
}

.header {
	margin-top: 0px;
	margin-bottom: 14px;
	font-size: 36px;
	/* line-height: 33px; */
	text-align: center;
	font-weight: 700;
	font-family: 'SF Display Pro', Inter;
}

.tagline {
	margin: 0;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.5);
	font-weight: 400;
	text-align: center;
	font-family: Inter;
}

.button {
	display: inline-block;
	height: 48px;
	width: 300px;
	font-size: 16px;
	border: 1px solid transparent;
	border-radius: 10px;
	cursor: pointer;
}

.backButton {
	background-color: transparent;
	width: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.blueButton {
	background-color: #007aff;
	color: #fff;
	margin: 0 auto;
	display: block;
}

.backIcon {
	height: 16px;
	width: 8px;
}

.slimButton {
	height: 42px;
}

.button:disabled {
	color: #ababbb;
	background-color: #f2f2f7;
	cursor: not-allowed;
}

.inputLabel {
	font-size: 16px;
	margin-bottom: 12px;
	display: block;
	color: #000000;
}

.input {
	border: 1px solid #f2f2f7;
	background-color: #fff;
	color: #060518;
	padding: 16px 12px;
	border-radius: 10px;
	font-size: 16px;
	font-weight: 600;
	width: 100%;
}

.input::placeholder {
	color: rgba(6, 5, 24, 0.3);
	font-weight: 500;
}

.percentageIcon {
	height: 6px;
	display: block;
	margin: 0 6px;
	/* margin-top: auto; */
}

.button_fit {
	width: 100px;
}

.error {
	margin: 0;
	display: inline-block;
	color: #ff4b4b;
}

.hasIcon {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	gap: 10px;
}